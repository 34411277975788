import { FC } from 'react';
import OneTrust from '../OneTrust/OneTrust';
import GoogleTagManager from './GoogleTagManager';

const ThirdPartyScripts: FC = () => (
  <>
    <GoogleTagManager />
    <OneTrust />
  </>
);

export default ThirdPartyScripts;
