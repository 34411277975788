import { FC, PropsWithChildren } from 'react';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

const Container = styled.section<{ horizontalPadding: number }>`
  display: flex;
  justify-content: center;
  padding-left: ${({ horizontalPadding }) => `${horizontalPadding}px`};
  padding-right: ${({ horizontalPadding }) => `${horizontalPadding}px`};
`;

const Content = styled.section<{
  maxWidth: number;
  extraStyle?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  flex: 1;
  ${({ extraStyle }) => extraStyle}
`;

type Props = {
  maxWidth?: number;
  className?: string;
  horizontalPadding?: number;
  innerStyle?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
} & PropsWithChildren;

const ResponsiveContainer: FC<Props> = ({
  children,
  maxWidth,
  className,
  horizontalPadding,
  innerStyle,
}) => (
  <Container horizontalPadding={horizontalPadding || 20} className={className}>
    <Content maxWidth={maxWidth || 1120} extraStyle={innerStyle}>
      {children}
    </Content>
  </Container>
);

export default ResponsiveContainer;
