import styled from 'styled-components';
import SecondaryButton from './SecondaryButton';

const XSmallSecondaryButton = styled(SecondaryButton)`
  height: 28px;
  min-height: 0;
  min-width: 0;
  font-size: 1.2rem;
  padding: 6px 8px;
`;

export default XSmallSecondaryButton;
