import getConfig from 'next/config';
import Script from 'next/script';
import { FC } from 'react';

const { publicRuntimeConfig } = getConfig();

const OneTrust: FC = () => (
  <>
    <Script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      data-domain-script={publicRuntimeConfig.ONE_TRUST_SCRIPT}
    />
    <Script
      key="oneTrustInit"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: 'function OptanonWrapper() { }',
      }}
    />
  </>
);

export default OneTrust;
