import { useApolloClient } from '@apollo/client';
import { FC, useContext, useMemo } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import {
  CartFragment,
  CartProductFragment,
  ProductState,
  RemoveFromCartDocument,
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables,
} from '../../generated/graphql';
import { translationKeyForState } from '../../utils/product';
import CartItem, { CartItemProps } from './CartItem';

const CartItems: FC<{ cart: CartFragment; canRemoveFromCart: boolean }> = ({
  cart,
  canRemoveFromCart,
}) => {
  const { translate } = useContext(LanguageContext);
  const apolloClient = useApolloClient();

  const productToCartItem = (item: CartProductFragment): CartItemProps => ({
    productId: item.id,
    title: item.brand.name,
    subtitle:
      item.state !== ProductState.Active
        ? translate(translationKeyForState(item.state))
        : item.size && item.size.sizeOptions.length
        ? translate('gen_size_2', item.size.sizeOptions[0].title)
        : translate('gen_accessory'),
    isActive: item.state === ProductState.Active,
    removeFromCart: canRemoveFromCart ? () => removeFromCart(item.id) : null,
    imageUrl: item.image?.url,
    outletDiscount:
      item.outletDiscount && item.outletDiscount.discountPercentage,
    preOutletPrice:
      item.outletDiscount && item.outletDiscount.preOutletPrice.amount,
    price: item.price.amount,
  });

  const items: CartItemProps[] = useMemo(
    () =>
      cart.groups.flatMap((group) =>
        group.items.flatMap((item) => {
          switch (item.__typename) {
            case 'Product':
              return productToCartItem(item);
            case 'ProductBidGroup':
              return {
                ...productToCartItem({
                  ...item.product,
                  state: ProductState.Active,
                }),
                removeFromCart: null,
                outletDiscount: null,
                preOutletPrice: null,
                price: item.bids[0].price.amount,
              };
            case 'BundleBidGroup':
              return {
                title: translate('bids_bundle'),
                subtitle: translate(
                  'cart_bundle_product_number',
                  item.products.length
                ),
                isActive: true,
                imageUrl: item.image?.url,
                price: item.bids[0].price.amount,
              };
            default:
              return [];
          }
        })
      ),
    [cart]
  );

  const removeFromCart = async (productId: string) => {
    const newCart = {
      ...cart,
      groups: [
        ...cart.groups
          .map((group) => ({
            ...group,
            items: group.items.filter(
              (item) =>
                !(item.__typename === 'Product' && item.id === productId)
            ),
          }))
          .filter((group) => group.items.length),
      ],
    };
    apolloClient.mutate<
      RemoveFromCartMutation,
      RemoveFromCartMutationVariables
    >({
      mutation: RemoveFromCartDocument,
      variables: { input: { productId } },
      optimisticResponse: {
        removeFromCart: {
          __typename: 'RemoveFromCartPayload',
          shoppingCart: newCart,
          product: {
            __typename: 'Product',
            id: productId,
            viewerHasInCart: false,
          },
        },
      },
    });
  };

  return (
    <>
      {items.map((item) => (
        <CartItem key={item.productId || item.imageUrl} {...item} />
      ))}
    </>
  );
};

export default CartItems;
