import useFormatPrice from '@/common/hooks/useFormatPrice';
import { Caption2, Heading5 } from '@/modules/design/components/textstyles';
import Link from 'next/link';
import { transparentize } from 'polished';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../context/LanguageContext';

export interface CartItemProps {
  productId?: string;
  title: string;
  subtitle: string;
  isActive: boolean;
  removeFromCart?: () => void;
  imageUrl: string;
  outletDiscount?: number;
  preOutletPrice: number;
  price: number;
}

const StyledProduct = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.35)};

  &:first-of-type {
    margin-top: 0;
  }
`;

const ProductImage = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 12px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ProductLabels = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`;

const ProductTitle = styled(Heading5)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ProductSubtitle = styled(Caption2)`
  margin-top: 2px;
`;

const PriceLabels = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const PreOutletPrice = styled(Caption2)`
  color: ${({ theme }) => theme.colors.CoralCS3};
  text-decoration: line-through;
  margin-right: 2px;
`;

const OutletDiscount = styled(Caption2)`
  position: absolute;
  bottom: 8px;
  padding: 0 8px;
  left: 0;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.CG7};
  background-color: ${({ theme }) =>
    transparentize(0.15, theme.colors.CoralCS3)};
`;

const RemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  background-image: ${() =>
    `url(${require('@/modules/assets/icons/close-small.svg')})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

const CartItem: FC<CartItemProps> = ({
  productId,
  isActive,
  title,
  subtitle,
  removeFromCart,
  imageUrl,
  outletDiscount,
  preOutletPrice,
  price,
}) => {
  const { locale } = useContext(LanguageContext);
  const formatPrice = useFormatPrice();
  return (
    <StyledProduct isActive={isActive}>
      <ProductImage>
        {productId ? (
          <Link href={`/product/${productId}`} passHref>
            <a>
              <img src={imageUrl} alt={title} />
            </a>
          </Link>
        ) : (
          <img src={imageUrl} alt={title} />
        )}
        {outletDiscount ? (
          <OutletDiscount>{`-${outletDiscount}%`}</OutletDiscount>
        ) : null}
      </ProductImage>
      <ProductLabels>
        <ProductTitle>{title}</ProductTitle>
        <ProductSubtitle>{subtitle}</ProductSubtitle>
      </ProductLabels>
      <PriceLabels>
        {preOutletPrice ? (
          <PreOutletPrice>{formatPrice(preOutletPrice, locale)}</PreOutletPrice>
        ) : null}
        <Heading5>{formatPrice(price, locale)}</Heading5>
      </PriceLabels>
      {removeFromCart ? <RemoveIcon onClick={removeFromCart} /> : null}
    </StyledProduct>
  );
};

export default CartItem;
