import { EventsContext } from '@/common/context/EventsContext';
import { LanguageContext } from '@/common/context/LanguageContext';
import { getClosureUrl } from '@/common/utils/url';
import PrimaryButton from '@/modules/design/components/buttons/PrimaryButton';
import { BodyText, Heading3 } from '@/modules/design/components/textstyles';
import { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 32px 24px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`;

const StyledBodyText = styled(BodyText)`
  margin-top: 32px;
`;

const LearnMoreButton = styled(PrimaryButton)`
  margin-top: 40px;
`;

const ClosureNotice: FC = () => {
  const { translate, language } = useContext(LanguageContext);
  const { logEvent } = useContext(EventsContext);

  useEffect(() => {
    logEvent('error_not_possible');
  }, []);

  const handleOnLearnMoreClick = () => {
    logEvent('info_closure_banner');
  };

  return (
    <Container>
      <Heading3>{translate('closure_placeholder_title')}</Heading3>
      <StyledBodyText>{translate('closure_placeholder_expl')}</StyledBodyText>
      <LearnMoreButton
        as="a"
        target="_blank"
        href={getClosureUrl(language)}
        onClick={handleOnLearnMoreClick}
      >
        {translate('closure_learn_more_cta')}
      </LearnMoreButton>
    </Container>
  );
};

export default ClosureNotice;
