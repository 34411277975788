import getConfig from 'next/config';
import Script from 'next/script';
import { FC } from 'react';

const { publicRuntimeConfig } = getConfig();

const GoogleTagManager: FC = () => (
  <>
    <Script
      key="googleTagManager"
      dangerouslySetInnerHTML={{
        __html: `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM_ID}');`,
      }}
    />
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${publicRuntimeConfig.GTM_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  </>
);

export default GoogleTagManager;
